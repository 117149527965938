import React, { useEffect, useState } from "react";
import "./overlay.css";
import "./form.css";
import "./Analysis.css";
import AnalysisTestimonial from "./AnalysisTestimonial.js";
import Research_Pro_Logo from "../assets/Research_Pro_Logo_2.webp";
import DataBanner from "../assets/data-banner.webp";
import DataBannerMobile from "../assets/data-banner-mobile.webp";
import QuizImg from "../assets/quiz.png";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import countryList from "react-select-country-list";
import Select from "react-select";
import Cookies from "js-cookie";
import {
  RiCloseCircleFill,
  RiFacebookCircleFill,
  RiWhatsappFill,
  RiLinkedinBoxFill,
  RiMailFill,
  RiTwitterXFill,
  RiShare2Fill,
  RiCloseLine,
} from "@remixicon/react";

const AnalysisEvent = () => {
  const [latestEvent, setLatestEvent] = React.useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [workshopDate, setWorkshopDate] = useState("");
  const [isPayPalVisible, setIsPayPalVisible] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [minDate, setMinDate] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [closingConfirm, setClosingConfirm] = useState(false);
  const [formData, setFormData] = useState({
    curriculumUser: "",
    email: "",
    country: selectedCountry?.label || "Not specified",
    CurriculumName: "Data-Analysis-Curriculum",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [showQuizPopup, setShowQuizPopup] = useState(false);
  const [isPopup1Open, setIsPopup1Open] = useState(false);
  const [counter, setCounter] = useState(3232);

  useEffect(() => {
    const fetchCounter = async () => {
      try {
        const response = await axios.get(
          "https://researchpro.online/api/counter"
        );
        setCounter(response.data.counter);
      } catch (error) {
        console.error("Error fetching counter:", error);
      }
    };

    fetchCounter();
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = currentDate.toLocaleDateString("en-US", options);
    setWorkshopDate(formattedDate);
  }, []);

  useEffect(() => {
    if (!isPopup1Open) {
      const timer = setTimeout(() => {
        setShowQuizPopup(true);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [isPopup1Open]);

  const handleOpenPopup1 = () => {
    setIsPopup1Open(true);
    setShowQuizPopup(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      country: selectedCountry?.label || "Not specified",
    }));
  };

  const handleCurriculumSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const country = selectedCountry?.label || "Not specified";
      const updatedFormData = {
        ...formData,
        country,
      };

      const response = await axios.post(
        "https://researchpro.online/analysis/curriculum-download",
        updatedFormData
      );

      setShowForm(false);
      alert("The curriculum has been emailed to you successfully!");
    } catch (error) {
      console.error("Error saving user or sending email:", error);
      alert(
        "An error occurred while processing your request. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleShare = (platform) => {
    const url = encodeURIComponent(window.location.href);
    let shareUrl = "";

    switch (platform) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?url=${url}&text=Check%20this%20out!`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;
        break;
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=Check this out: ${url}`;
        break;
      case "email":
        shareUrl = `mailto:?subject=Check%20this%20out&body=Check%20this%20out: ${url}`;
        break;
      default:
        break;
    }

    window.open(shareUrl, "_blank");
  };

  useEffect(() => {
    const minSelectableDate = new Date("2024-11-23");
    const formattedDate = minSelectableDate.toISOString().split("T")[0];
    setMinDate(formattedDate);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(latestEvent);
    const eventId = latestEvent?._id;
    const workshopId = "Data Analysis";
    const country = selectedCountry?.label || "Not specified";

    try {
      const response = await axios.post(
        "https://researchpro.online/formSubmit",
        { name, email, eventId, workshopId, country }
      );
      if (response.status === 200) {
        localStorage.setItem("email", email);
        setIsPayPalVisible(true);
        setIsFormSubmitted(true);
      }
      console.log(name, email);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleClose = () => {
    setClosingConfirm(true);
  };

  const handleConfirmClose = (confirmed) => {
    if (confirmed) {
      setIsFormSubmitted(false);
      setIsPayPalVisible(false);
      setName("");
      setEmail("");
      setSelectedCountry(null);
    }
    setClosingConfirm(false);
  };

  const location = useLocation();

  useEffect(() => {
    async function fetchLatestEvent() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/get-latest-event`
        );
        const event = response.data;
        setLatestEvent(event);
      } catch (error) {
        console.error("Error fetching latest event:", error);
      }
    }
    fetchLatestEvent();
    if (location.pathname === "/analysis") {
      document.body.style.backgroundColor = "#ffffff";
    } else {
      document.body.style.backgroundColor = "#d7c6f3";
    }

    return () => {
      document.body.style.backgroundColor = "";
    };
  }, [location.pathname]);

  // const convertBufferToBlobUrl = (buffer, contentType) => {
  //   // console.log("Buffer:", buffer);
  //   // console.log("Content Type:", contentType);
  //   if (buffer && contentType) {
  //     const byteArray = new Uint8Array(buffer.data);
  //     const blob = new Blob([byteArray], { type: contentType });
  //     const blobUrl = URL.createObjectURL(blob);
  //     // console.log("Generated Blob URL:", blobUrl);
  //     return blobUrl;
  //   }
  //   return null;
  // };

  // const imageSrc = useMemo(() => {
  //   return convertBufferToBlobUrl(
  //     latestEvent.image?.data,
  //     latestEvent.image?.contentType
  //   );
  // }, [latestEvent.image?.data, latestEvent.image?.contentType]);

  useEffect(() => {
    if (isPayPalVisible) {
      const script = document.createElement("script");
      script.src =
        "https://www.paypal.com/sdk/js?client-id=BAAYWXYvMr4CcyPUugFMlYcVLnq3IJBmWAmkw2ExaA4hGWM83mOa4sfikVNFUT8HkyjKKC22ihzRZoLWSw&components=hosted-buttons&disable-funding=venmo&currency=USD";
      script.addEventListener("load", () => {
        window.paypal
          .HostedButtons({
            hostedButtonId: "B7K5Q2SQYP398",
          })
          .render("#paypal-container-B7K5Q2SQYP398");
      });
      document.body.appendChild(script);
    }
  }, [isPayPalVisible]);

  return (
    <>
      {latestEvent && (
        <div className="upcoming-event-container">
          {/* Meta tags */}
          <Helmet>
            <title>
              Master Data Analysis: Workshop for Researchers | ResearchPro{" "}
            </title>
            <meta
              name="description"
              content="Master data analysis techniques with ResearchPro's expert-led workshop. Enhance your research skills with practical tools and advanced methodologies."
            />
          </Helmet>

          {showQuizPopup && !isPopup1Open && (
            <div className="quiz-popup">
              <div className="quiz-popup-content">
                <RiCloseLine
                  className="quiz-popup-close"
                  color="#D1410C"
                  size={38}
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowQuizPopup(false)}
                />
                <div className="quiz-popup-left">
                  <img src={QuizImg} alt="quizimg" />
                </div>
                <div className="quiz-popup-right">
                  <h3>
                    <span>👥{counter.toLocaleString()} </span>
                    People have Played the Quiz!
                  </h3>
                  <h2>It's Your Chance Now.</h2>
                  <a href="/data-analysis-quiz">Test Your Knowlegde!</a>
                </div>
              </div>
            </div>
          )}

          <div className="analysis-header">
            {!DataBanner ? (
              <div className="skeleton-loader">Loading...</div>
            ) : (
              <img
                loading="lazy"
                src={isMobile ? DataBannerMobile : DataBanner}
                alt="DataImage"
              />
            )}
          </div>

          <div className="event-details">
            <div className="event-details-header">
              <div className="left-half-event-details">
                <h2 className="page-title">
                  Unlock the Power of Data Analysis for Your Research
                </h2>
                {isOpen && (
                  <div className="share-popup-overlay">
                    <div className="share-popup">
                      <div className="share-head">
                        <h3>Share With Friends</h3>
                        <RiCloseCircleFill
                          color="#d1410c"
                          style={{ cursor: "pointer" }}
                          onClick={togglePopup}
                        />
                      </div>
                      <div className="share-cont">
                        <div className="share-button-cont">
                          <button
                            className="share-button"
                            onClick={() => handleShare("facebook")}
                          >
                            <RiFacebookCircleFill />
                          </button>
                          <button
                            className="share-button"
                            onClick={() => handleShare("twitter")}
                          >
                            <RiTwitterXFill />
                          </button>
                          <button
                            className="share-button"
                            onClick={() => handleShare("linkedin")}
                          >
                            <RiLinkedinBoxFill />
                          </button>
                          <button
                            className="share-button"
                            onClick={() => handleShare("whatsapp")}
                          >
                            <RiWhatsappFill />
                          </button>
                          <button
                            className="share-button"
                            onClick={() => handleShare("email")}
                          >
                            <RiMailFill />
                          </button>
                        </div>
                        <div className="link-copy-container">
                          <input
                            type="text"
                            className="link-box"
                            value={window.location.href}
                            readOnly
                          />
                          <button
                            className="copy-button"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                window.location.href
                              );
                            }}
                          >
                            Copy Link
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="right-half-event-details">
                <div className="payment-info">
                  <div className="box larger-than-800">
                    <p>
                      <strong>Limited Time Offer:</strong> <strong>$199</strong>
                    </p>
                    <p>
                      <strong>Regular Price: </strong>
                      <strong>$250</strong>
                    </p>
                    <a
                      className="button"
                      href="#popup1"
                      onClick={handleOpenPopup1}
                    >
                      <button>Register Now</button>
                    </a>
                  </div>
                </div>
              </div>

              {/* Second box for screen sizes less than 800px  */}
              <div className="box analysis-smaller-than-800">
                <p>
                  <strong>Limited Time Offer:</strong> $199,{" "}
                  <strong>Regular Price: </strong>
                  $250
                </p>
                <a className="button" href="#popup1" onClick={handleOpenPopup1}>
                  <button>Register Now</button>
                </a>
              </div>

              <div
                id="popup1"
                className={`overlay ${isPopup1Open ? "visible" : ""}`}
              >
                <div className="popup">
                  <div className="content">
                    {closingConfirm && (
                      <div className="closing-confirm">
                        <div className="closing-confirm-popup">
                          <span
                            className="close-of-closing"
                            onClick={handleConfirmClose}
                          >
                            ×
                          </span>
                          <h3>You're one step away from research success!</h3>
                          <p>
                            Are you sure you want to leave and miss this limited
                            time offer?
                          </p>
                          <button onClick={() => handleConfirmClose(false)}>
                            Continue
                          </button>
                          <a href="#a" onClick={() => handleConfirmClose(true)}>
                            Discard
                          </a>
                        </div>
                      </div>
                    )}
                    {!isFormSubmitted && (
                      <form
                        name="contact"
                        method="POST"
                        netlify
                        data-netlify="true"
                        onSubmit={(event) => handleSubmit(event)}
                      >
                        <h2>Register Now</h2>
                        <span className="close" onClick={handleClose}>
                          ×
                        </span>
                        <input type="hidden" name="form-name" value="contact" />
                        <input
                          type="hidden"
                          name="workshopId"
                          value="Data Analysis"
                        />
                        <label htmlFor="name">Name:</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                        <label htmlFor="email">Email:</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        {/* Country Dropdown */}
                        <label htmlFor="country">Country:</label>
                        <Select
                          options={countryList().getData()}
                          value={selectedCountry}
                          onChange={setSelectedCountry}
                          className="country-select"
                          required
                        />
                        <button type="submit">Continue Checkout</button>
                      </form>
                    )}
                    {isPayPalVisible && (
                      <div
                        id="paypal-container-B7K5Q2SQYP398"
                        style={{ display: "block" }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="yellow-strip">
              <h6>
                Workshop Date: <span>{workshopDate}</span>
              </h6>
              <RiShare2Fill
                color="#1E0A3C"
                className="share-icon"
                style={{
                  height: "2.2vmax",
                  width: "2.2vmax",
                  cursor: "pointer",
                }}
                onClick={togglePopup}
              />
              <a href="/data-analysis-quiz">Test Your Knowledge!</a>
            </div>

            <div className="proposal-display">
              <h2>Are You a Researcher struggling with Data Analysis?</h2>
              <ul>
                <li>
                  🤔 Confused about the best data collection methods for your
                  research?
                </li>
                <li>
                  📊 Unsure which statistical method to use - Regression, ANOVA,
                  Chi-Square, T-Test, or Correlational Analysis?
                </li>
                <li>
                  🛠️ Finding it challenging to use tools for qualitative data
                  analysis, like Thematic Analysis?
                </li>
              </ul>
            </div>
            <h2>About the Workshop</h2>
            <p className="about-info">In this workshop, you’ll learn how to:</p>
            <ul style={{ marginLeft: "-1vw" }} className="ul-why-join">
              <li>
                Choose the right Data collection and Analysis methods for your
                research.
              </li>
              <li>Apply Quantitative techniques with condence.</li>
              <li>
                Master Qualitative analysis tools to make sense of complex data.
              </li>
            </ul>
            <p className="about-info">
              The curriculum is divided into 5 focused sessions, each followed
              by time to apply what you’ve learned and ask questions through a
              dedicated query form. We’ll simplify complex concepts into
              actionable steps, equipping you to analyze your research data like
              a pro!
            </p>
            <div className="key-takeaways">
              <h2>Curriculum Details</h2>
              <ul className="analysis-ul">
                <li>
                  <strong>💡 Session 1:</strong> Foundations of Research and
                  Data Analysis
                </li>
                <li>
                  <strong>💡 Session 2:</strong> Preparing Your Data for
                  Analysis
                </li>
                <li>
                  <strong>💡 Session 3:</strong> Hands on Quantitative Data
                  Analysis
                </li>
                <li>
                  <strong>💡 Session 4:</strong> Hands on Qualitative Data
                  Analysis
                </li>
                <li>
                  <strong>💡 Session 5:</strong> Visualizing and Interpreting
                  Results
                </li>
              </ul>

              <button
                className="curriculum-download-btn da-btn"
                onClick={() => setShowForm(true)}
              >
                Download Detailed Curriculum
              </button>

              {showForm && (
                <div className="modal">
                  <form onSubmit={handleCurriculumSubmit}>
                    <RiCloseCircleFill
                      className="modal-close"
                      color="#000"
                      size={35}
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowForm(false)}
                    />
                    <h3>Enter Your Details</h3>
                    <p>
                      We will Send the Detailed Curriculum to the Email Address
                      you Provide.
                    </p>
                    <input
                      type="hidden"
                      id="CurriculumName"
                      name="CurriculumName"
                      value="Research-Proposal-Curriculum"
                    />
                    <input
                      type="text"
                      name="curriculumUser"
                      id="curriculumUser"
                      placeholder="Your Name"
                      value={formData.curriculumUser}
                      onChange={handleInputChange}
                      required
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                    <Select
                      name="country"
                      options={countryList().getData()}
                      value={selectedCountry}
                      onChange={(option) => {
                        setSelectedCountry(option);
                        setFormData((prevData) => ({
                          ...prevData,
                          country: option.label,
                        }));
                      }}
                      className="country-select"
                      placeholder="Select Country"
                      required
                    />
                    {isSubmitting ? (
                      <div className="curriculum-loader"></div>
                    ) : (
                      <button type="submit" className="modal-submit">
                        Submit & Download
                      </button>
                    )}
                  </form>
                </div>
              )}

              <h3>🎁 Bonus for Early Registrations!</h3>
              <p>Register Now and Get Exclusive Benefits:</p>
              <ul className="analysis-ul">
                <li>
                  <strong>▪️Exclusive Bonus Session</strong> to deepen your
                  understanding of advanced techniques.
                </li>
                <li>
                  <strong>▪️Priority Support: </strong> Get faster responses to
                  your queries.
                </li>
              </ul>
            </div>

            <div className="key-takeaways">
              <h2>Key Features</h2>
              <ul className="analysis-ul">
                <li>
                  <strong>▪️Certification:</strong> You will be a certified
                  researcher with proven skills in data analysis.
                </li>
                <li>
                  <strong>▪️Excel in Data Analysis:</strong> : Whether its
                  Qualitative or Quantitative analysis, you will be learning it
                  all in just 1 workshop.
                </li>
              </ul>
            </div>

            <h2>Why Join?</h2>
            <ul
              style={{ listStyle: "none", marginLeft: "-1vw" }}
              className="ul-why-join"
            >
              <li>
                <strong>🔍 Save Time and Frustration: </strong>
                Learn practical skills to simplify your research journey.
              </li>
              <li>
                <strong> 📈 Improve Your Research Quality: </strong>
                Master analysis techniques that ensure meaningful, impactful
                results.
              </li>
              <li>
                <strong>💡Research Skills at Textbook Prices: </strong>
                For less than the cost of a textbook, gain skills that will
                transform your research outcomes.
              </li>
            </ul>

            <div className="key-takeaways">
              <h2>Fee Structure</h2>
              <ul className="analysis-ul">
                <li>
                  <strong>▪️Limited Time Offer:</strong> $199 <br />
                  <span>
                    Please Note: Early registrations will receive a bonus
                    session from our end.
                  </span>
                </li>
                <li>
                  <strong>▪️Regular Price:</strong> $250
                </li>
              </ul>

              <h3>🔥Limited Spots Available:</h3>
              <ul
                style={{ listStyle: "none", marginLeft: "-1vw" }}
                className="ul-why-join"
              >
                <li>
                  Secure your spot today to elevate your data analysis skills
                  and become a certified researcher!
                </li>
              </ul>
            </div>
            <br />
            <AnalysisTestimonial />
            <hr />
            <div className="organizer-cont">
              <h3>Organised By-</h3>
              <div className="organizer-box">
                <div className="organizer-up">
                  <img src={Research_Pro_Logo} alt="ResearchPro Logo" />

                  <a href="/contactus">Contact Us</a>
                </div>
                <div className="organizer-down">
                  <h4>About Organisers</h4>
                  <p>
                    ResearchPro is a leading platform dedicated to empowering
                    PhD and master’s students in their research journey. With
                    expert-led webinars on proposal writing, research papers,
                    data analysis, and more, we provide valuable insights and
                    practical tools for academic success. Join ResearchPro and
                    unlock your full potential!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AnalysisEvent;
