import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./pages/Navbar";
import "./App.css";
import HomePage from "./pages/HomePage";
import UpcomingEvent from "./pages/UpcomingEvent";
import Footer from "./pages/Footer";
import Analytics from "./analytics/Analytics";
import TrackPageView from "./analytics/TrackPageView";
import ThankyouPage from "./pages/Thankyou/ThankyouPage";
import ContactUs from "./pages/contactus.js";
import Events from "./pages/Event.js";
import Analysis from "./pages/Analysis.js";
import ProposalQuiz from "./pages/ProposalQuiz.js";
import NewProposalQuiz from "./pages/NewProposalQuiz.js";
import DataAnalysisQuiz from "./pages/DataAnalysisQuiz.js";
import NewProposal from "./pages/NewProposal.js";

const App = () => {
  const location = useLocation();

  React.useEffect(() => {
    if (
      location.pathname === "/proposal" ||
      location.pathname === "/upcoming" ||
      location.pathname === "/analysis"
    ) {
      document.body.style.backgroundColor = "#FFFFFF";
    } else if (
      location.pathname === "/proposal-quiz" ||
      location.pathname === "/data-analysis-quiz" ||
      location.pathname === "/new-proposal-quiz"
    ) {
      document.body.style.backgroundColor = "#F8F8FF";
    } else {
      document.body.style.backgroundColor = "";
    }
  }, [location]);

  const isSpecialQuizPage =
    location.pathname === "/proposal-quiz" ||
    location.pathname === "/data-analysis-quiz" ||
    location.pathname === "/new-proposal-quiz";

  return (
    <div className="page-container">
      <Analytics />
      {!isSpecialQuizPage && <Navbar />}
      <Routes>
        <Route path="*" element={<HomePage />} />
        <Route path="/events" element={<Events />} />
        <Route path="/upcoming" element={<UpcomingEvent />} />
        <Route path="/proposal-quiz" element={<ProposalQuiz />} />
        <Route path="/proposal" element={<NewProposal />} />
        <Route path="/new-proposal-quiz" element={<NewProposalQuiz />} />
        <Route path="/analysis" element={<Analysis />} />
        <Route path="/data-analysis-quiz" element={<DataAnalysisQuiz />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/thank-you" element={<ThankyouPage />} />
      </Routes>
      <TrackPageView />
      {!isSpecialQuizPage && <Footer />}
    </div>
  );
};

export default App;
