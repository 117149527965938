import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import QuizIntro from "./ProposalQuiz/QuizIntro";
import ProposalQuestion from "./ProposalQuiz/ProposalQuestion";
import ProposalFactScreen from "./ProposalQuiz/ProposalFactScreen";
import ProposalQuizForm from "./ProposalQuiz/ProposalQuizFrom";
import Q1 from "../assets/Q1.webp";
import Q2 from "../assets/Q2.webp";
import Q3 from "../assets/Q3.webp";
import Q4 from "../assets/Q4.webp";
import Q5 from "../assets/Q5.webp";
import Q6 from "../assets/Q6.webp";
import Q7 from "../assets/Q7.webp";
import Q8 from "../assets/Q8.webp";
import Q9 from "../assets/Q9.webp";
import F1 from "../assets/F1.webp";
import F2 from "../assets/F2.webp";
import F3 from "../assets/F3.webp";
import F4 from "../assets/F4.webp";
import F5 from "../assets/F5.webp";
import "./ProposalQuiz.css";

const ProposalQuiz = () => {
  const navigate = useNavigate();
  const questions = [
    {
      question:
        "1. Which part of research proposal writing do you find most overwhelming?",
      options: [
        "A) Identifying a clear and feasible research problem.",
        "B) Writing a strong research gap without being too broad or vague.",
        "C) Designing a methodology that reviewers will approve.",
        "D) Structuring the proposal to meet strict academic standards.",
      ],
      fact: "60% of scholars report that writing a research proposal is the most stressful part of their program. ",
      factImage: { url: F1 },
      image: {
        url: Q1,
      },
      styles: { question: "france-question", options: "france-options" },
    },

    {
      question:
        "2. Have you ever felt stuck identifying a significant research gap in your field?",
      options: [
        "A) Yes, narrowing down gaps is time-consuming and confusing.",
        "B) No, but I’m unsure if the gaps I identify are impactful.",
        "C) Sometimes, but I wish there was a step-by-step process to simplify it.",
      ],
      fact: "Our workshop helps you craft precise research problems that captivate reviewers. ",
      factImage: { url: F2 },
      image: {
        url: Q2,
      },
      styles: { question: "webdev-question", options: "webdev-options" },
    },
    {
      question:
        "3. How confident are you in writing an engaging introduction and problem statement?",
      options: [
        "A) Very confident—I can write it independently. ",
        "B) Somewhat confident—I need guidance to make it impactful.",
        "C) Not confident—I often rely on trial and error.",
      ],
      image: {
        url: Q3,
      },
      styles: { question: "water-question", options: "water-options" },
    },
    {
      question:
        "4. How often do you feel dependent on your supervisor for proposal writing? ",
      options: [
        "A) Frequently—I struggle to proceed without their input.",
        "B) Occasionally—only for complex issues.",
        "C) Rarely—but I’d like to feel completely independent.",
        "D) I don’t have a supportive supervisor.",
      ],
      fact: "30% of students report receiving minimal or no guidance from their supervisors.",
      factImage: { url: F3 },
      image: {
        url: Q4,
      },
      styles: { question: "water-question", options: "water-options" },
    },
    {
      question:
        "5. What’s your biggest roadblock in writing the research significance section?",
      options: [
        "A) Convincing reviewers why my research matters.",
        "B) Connecting significance to my objectives and methods. ",
        "C) Avoiding repetitive or vague statements.",
        "D) I’m unsure what this section is even about.",
      ],
      image: {
        url: Q5,
      },
      styles: { question: "water-question", options: "water-options" },
    },
    {
      question:
        "6. Do you often feel rushed or unprepared when meeting proposal deadlines?",
      options: [
        "A) Yes, I scramble to put things together at the last minute.",
        "B) Sometimes—I manage, but I feel stressed and uncertain.",
        "C) Rarely—but I’d like to streamline my writing process. ",
      ],
      image: {
        url: Q6,
      },
      styles: { question: "water-question", options: "water-options" },
    },
    {
      question:
        "7. If you had access to expert proposal writing guidance, how likely are you to attend a workshop?",
      options: [
        "A) Very likely—I need structured help to excel.",
        "B) Likely—I want to sharpen my skills and confidence.",
        "C) Unlikely—but I’d like to explore more details about the workshop.",
      ],
      fact: "Join hundreds of scholars who’ve transformed their writing skills with our proven methods!",
      factImage: { url: F4 },
      image: {
        url: Q7,
      },
      styles: { question: "water-question", options: "water-options" },
    },
    {
      question:
        "8. Do you agree that strong proposal writing skills are the foundation of successful research?",
      options: [
        "A) Strongly agree—poor proposals lead to poor research opportunities.",
        "B) Agree—but I’m unsure where to begin learning.",
        "C) Disagree—I focus more on the research itself.",
      ],
      image: {
        url: Q8,
      },
      styles: { question: "water-question", options: "water-options" },
    },
    {
      question:
        "9. What would help you feel more confident in your proposal writing?",
      options: [
        "A) Step-by-step guidance for each section.",
        "B) Tools to identify and articulate research gaps effectively.",
        "C) Access to expert feedback and reviews.",
        "D) Examples of successful research proposals.",
        "E) Free plagiarism tests and personalized Q&A sessions.",
      ],
      fact: "Our workshop provides ALL these benefits, with exclusive bonuses for early registrations!",
      factImage: { url: F5 },
      image: {
        url: Q9,
      },
      styles: { question: "water-question", options: "water-options" },
    },
  ];

  const totalSteps = 1 + questions.length * 2 + 1;

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [state, setState] = useState({
    currentQuestion: 0,
    selectedOption: null,
    showIntro: true,
    showFact: false,
    showForm: false,
    userData: {
      name: "",
      email: "",
      country: selectedCountry?.label || "Not specified",
      QuizName: "Research-Proposal-Quiz",
    },
    imageLoaded: false,
  });

  const {
    currentQuestion,
    selectedOption,
    showIntro,
    showFact,
    showForm,
    userData,
    imageLoaded,
  } = state;

  useEffect(() => {
    const preloadImages = (imageUrls) => {
      imageUrls.forEach((url) => {
        const img = new Image();
        img.src = url;
      });
    };
    const allImages = [Q1, Q2, Q3, Q4, Q5, Q6, Q7, Q8, Q9, F1, F2, F3, F4, F5];
    preloadImages(allImages);
  }, []);

  const currentStep = showIntro
    ? 1
    : showForm
    ? totalSteps
    : showFact
    ? currentQuestion * 2 + 2
    : currentQuestion * 2 + 1;

  const progressPercentage = (currentStep / totalSteps) * 100;

  const handleOptionSelect = (option) => {
    setState((prevState) => ({ ...prevState, selectedOption: option }));
  };

  const handleNext = () => {
    if (questions[currentQuestion].fact && !showFact) {
      setState((prevState) => ({
        ...prevState,
        showFact: true,
        selectedOption: null,
        imageLoaded: false,
      }));
    } else {
      moveToNextQuestion();
    }
  };

  const moveToNextQuestion = () => {
    const isLastQuestion = currentQuestion + 1 === questions.length;

    if (isLastQuestion) {
      setState((prevState) => ({
        ...prevState,
        showForm: true,
        showFact: false,
        selectedOption: null,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: prevState.currentQuestion + 1,
        showFact: false,
        selectedOption: null,
        imageLoaded: false,
      }));
    }
  };

  const handleImageLoad = () => {
    setState((prevState) => ({ ...prevState, imageLoaded: true }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      userData: { ...prevState.userData, [name]: value },
      country: selectedCountry?.label || "Not specified",
    }));
  };

  const quizFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://researchpro.online/proposal-quiz/quizFormData",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(userData),
        }
      );

      if (!response.ok) throw new Error("Failed to save form data.");

      navigate("/upcoming");
    } catch (error) {
      alert("There was an error submitting the form. Please try again later.");
    }
  };

  const animationVariants = {
    enter: { x: "100%", opacity: 0, scale: 1 },
    center: { x: 0, opacity: 1, scale: 1, transition: { duration: 0.5 } },
    exit: { x: "-100%", opacity: 0, scale: 1, transition: { duration: 0.5 } },
  };
  return (
    <div className="proposal-quiz-cont">
      {/* Progress Bar */}
      <div className="progress-bar-container">
        <div
          className="progress-bar"
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
      <AnimatePresence mode="wait">
        {showIntro && (
          <motion.div
            key="intro"
            initial="enter"
            animate="center"
            exit="exit"
            variants={animationVariants}
          >
            <QuizIntro
              onNext={() =>
                setState((prevState) => ({ ...prevState, showIntro: false }))
              }
            />
          </motion.div>
        )}
        {!showIntro && !showForm && !showFact && (
          <motion.div
            key={`question-${currentQuestion}`}
            initial="enter"
            animate="center"
            exit="exit"
            variants={animationVariants}
          >
            <ProposalQuestion
              questionData={questions[currentQuestion]}
              selectedOption={selectedOption}
              onSelectOption={handleOptionSelect}
              onNext={handleNext}
              disableNext={!selectedOption}
              onImageLoad={() =>
                setState((prevState) => ({ ...prevState, imageLoaded: true }))
              }
              imageLoaded={imageLoaded}
            />
          </motion.div>
        )}
        {showFact && (
          <ProposalFactScreen
            fact={questions[currentQuestion].fact}
            onContinue={moveToNextQuestion}
            factImage={questions[currentQuestion].factImage}
          />
        )}
        {showForm && (
          <motion.div
            key="form"
            initial="enter"
            animate="center"
            exit="exit"
            variants={animationVariants}
          >
            <ProposalQuizForm
              userData={userData}
              onChange={handleInputChange}
              onSubmit={quizFormSubmit}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ProposalQuiz;
