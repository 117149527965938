// UserForm.js
import React from "react";
import ThankYou from "../../assets/thank-you.webp";
import "../ProposalQuiz/ProposalQuizForm.css";
import countryList from "react-select-country-list";
import Select from "react-select";

const UserForm = ({
  userData,
  onChange,
  onSubmit,
  selectedCountry,
  setSelectedCountry,
}) => (
  <div className="quiz-form-cont">
    <img className="thankyou-img" src={ThankYou} alt="Thank You" />
    <form className="user-form" onSubmit={onSubmit}>
      <h2>We'd love to hear from you!</h2>
      <label htmlFor="name">Name:</label>
      <input
        type="text"
        id="name"
        name="name"
        value={userData.name}
        onChange={onChange}
        required
      />
      <label htmlFor="email">Email:</label>
      <input
        type="email"
        id="email"
        name="email"
        value={userData.email}
        onChange={onChange}
        required
      />
      <label htmlFor="country">Country:</label>
      <Select
        name="country"
        options={countryList().getData()}
        value={selectedCountry}
        onChange={(option) => {
          setSelectedCountry(option);
          onChange({
            target: { name: "country", value: option.label },
          });
        }}
        className="country-select"
        placeholder="Select Country"
        required
      />
      {/* Hidden input field */}
      <input
        type="hidden"
        id="QuizName"
        name="New-Proposal-Quiz"
        value={userData.hiddenValue}
      />
      <button className="quiz-submit-btn" type="submit">
        Submit
      </button>
    </form>
  </div>
);

export default UserForm;
