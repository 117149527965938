import React, { useEffect, useState } from "react";
import "./overlay.css";
import "./form.css";
import "./NewProposal.css";
import NewProposalTestimonial from "./NewProposalTestimonial";
import Research_Pro_Logo from "../assets/Research_Pro_Logo_2.webp";
import ResearchProposal from "../assets/ProposalDesktop.png";
import ResearchProposalMobile from "../assets/Proposal.png";
import QuizImg from "../assets/quiz.png";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import countryList from "react-select-country-list";
import Select from "react-select";
import {
  RiCloseCircleFill,
  RiFacebookCircleFill,
  RiWhatsappFill,
  RiLinkedinBoxFill,
  RiMailFill,
  RiTwitterXFill,
  RiShare2Fill,
  RiCloseLine,
} from "@remixicon/react";

const Proposal = () => {
  const [latestEvent, setLatestEvent] = React.useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isPayPalVisible, setIsPayPalVisible] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [closingConfirm, setClosingConfirm] = useState(false);
  const [formData, setFormData] = useState({
    curriculumUser: "",
    email: "",
    country: selectedCountry?.label || "Not specified",
    CurriculumName: "New-Proposal-Curriculum",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [showQuizPopup, setShowQuizPopup] = useState(false);
  const [isPopup1Open, setIsPopup1Open] = useState(false);
  const [counter, setCounter] = useState(3232);
  const proposalPurchase = 99.99;
  const [weekendDates, setWeekendDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [seatsLeft, setSeatsLeft] = useState(null);

  useEffect(() => {
    const fetchCounter = async () => {
      try {
        const response = await axios.get(
          "https://researchpro.online/api/counter"
        );
        setCounter(response.data.counter);
      } catch (error) {
        console.error("Error fetching counter:", error);
      }
    };

    fetchCounter();
  }, []);

  useEffect(() => {
    const today = new Date();
    const upcomingDates = [];
    let currentDate = new Date();
    for (let i = 0; i < 14; i++) {
      const dayOfWeek = currentDate.getDay();
      if ([5, 6, 0].includes(dayOfWeek)) {
        if (currentDate >= today) {
          upcomingDates.push(new Date(currentDate));
        }
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }

    setWeekendDates(upcomingDates);
  }, []);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    const randomSeatsLeft = Math.floor(Math.random() * 7) + 2; 
    setSeatsLeft(randomSeatsLeft);
  };

  useEffect(() => {
    if (!isPopup1Open) {
      const timer = setTimeout(() => {
        setShowQuizPopup(true);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [isPopup1Open]);

  const handleOpenPopup1 = () => {
    setIsPopup1Open(true);
    setShowQuizPopup(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      country: selectedCountry?.label || "Not specified",
    }));
  };

  const handleCurriculumSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const country = selectedCountry?.label || "Not specified";
      const updatedFormData = {
        ...formData,
        country,
      };

      const response = await axios.post(
        "https://researchpro.online/proposal/curriculum-download",
        updatedFormData
      );

      setShowForm(false);
      alert("The curriculum has been emailed to you successfully!");
    } catch (error) {
      console.error("Error saving user or sending email:", error);
      alert(
        "An error occurred while processing your request. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleShare = (platform) => {
    const url = encodeURIComponent(window.location.href);
    let shareUrl = "";

    switch (platform) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?url=${url}&text=Check%20this%20out!`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;
        break;
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=Check this out: ${url}`;
        break;
      case "email":
        shareUrl = `mailto:?subject=Check%20this%20out&body=Check%20this%20out: ${url}`;
        break;
      default:
        break;
    }

    window.open(shareUrl, "_blank");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedDate) {
      alert("Please select a date!");
      return;
    }

    const eventId = latestEvent?._id;
    const workshopId = "New Research Proposals";
    const country = selectedCountry?.label || "Not specified";

    try {
      const response = await axios.post(
        "https://researchpro.online/formSubmit",
        {
          name,
          email,
          eventId,
          workshopId,
          country,
          selectedDate: selectedDate.toISOString(),
        }
      );
      if (response.status === 200) {
        localStorage.setItem("email", email);
        setIsPayPalVisible(true);
        setIsFormSubmitted(true);
      }
      console.log(name, email);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleClose = () => {
    setClosingConfirm(true);
  };

  const handleConfirmClose = (confirmed) => {
    if (confirmed) {
      setIsFormSubmitted(false);
      setIsPayPalVisible(false);
      setName("");
      setEmail("");
      setSelectedCountry(null);
    }
    setClosingConfirm(false);
  };

  const location = useLocation();

  useEffect(() => {
    async function fetchLatestEvent() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/get-latest-event`
        );
        const event = response.data;
        setLatestEvent(event);
      } catch (error) {
        console.error("Error fetching latest event:", error);
      }
    }
    fetchLatestEvent();
    if (location.pathname === "/proposal") {
      document.body.style.backgroundColor = "#ffffff";
    } else {
      document.body.style.backgroundColor = "#d7c6f3";
    }

    return () => {
      document.body.style.backgroundColor = "";
    };
  }, [location.pathname]);


  useEffect(() => {
    if (isPayPalVisible) {
      const script = document.createElement("script");
      script.src =
        "https://www.paypal.com/sdk/js?client-id=BAAYWXYvMr4CcyPUugFMlYcVLnq3IJBmWAmkw2ExaA4hGWM83mOa4sfikVNFUT8HkyjKKC22ihzRZoLWSw&components=hosted-buttons&disable-funding=venmo&currency=USD";
      script.addEventListener("load", () => {
        window.paypal
          .HostedButtons({
            hostedButtonId: "YF4R9EKFZRJ2L",
          })
          .render("#paypal-container-YF4R9EKFZRJ2L");
      });
      document.body.appendChild(script);
    }
  }, [isPayPalVisible]);

  return (
    <>
      {latestEvent && (
        <div className="upcoming-event-container">
          {/* Meta tags */}
          <Helmet>
            <title>Research Proposal Workshop | ResearchPro</title>
            <meta
              name="description"
              content="Join ResearchPro's specialized workshops on research proposal writing and AI techniques."
            />
            <script>
              {`
            fbq('track', 'Purchase', {
              value: ${proposalPurchase}, // Use the JavaScript variable
              currency: 'USD',
            });
          `}
            </script>
          </Helmet>

          {showQuizPopup && !isPopup1Open && (
            <div className="quiz-popup">
              <div className="quiz-popup-content">
                <RiCloseLine
                  className="quiz-popup-close"
                  color="#D1410C"
                  size={38}
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowQuizPopup(false)}
                />
                <div className="quiz-popup-left">
                  <img src={QuizImg} alt="quizimg" />
                </div>
                <div className="quiz-popup-right">
                  <h3>
                    <span>👥{counter.toLocaleString()} </span>
                    People have Played the Quiz!
                  </h3>
                  <h2>It's Your Chance Now.</h2>
                  <a href="/new-proposal-quiz">Test Your Knowlegde!</a>
                </div>
              </div>
            </div>
          )}

          <div className="header proposal-header">
            {!ResearchProposal ? (
              <div className="skeleton-loader">Loading...</div>
            ) : (
              <img
                loading="lazy"
                className="proposal-img"
                src={isMobile ? ResearchProposalMobile : ResearchProposal}
                alt="EventImage"
              />
            )}
          </div>

          <div className="event-details">
            <div className="event-details-header">
              <div className="left-half-event-details">
                <h2 className="page-title">
                  Conquer Your Research Proposal – From Topic to Final
                  Formatting!
                </h2>
                {isOpen && (
                  <div className="share-popup-overlay">
                    <div className="share-popup">
                      <div className="share-head">
                        <h3>Share With Friends</h3>
                        <RiCloseCircleFill
                          color="#d1410c"
                          style={{ cursor: "pointer" }}
                          onClick={togglePopup}
                        />
                      </div>
                      <div className="share-cont">
                        <div className="share-button-cont">
                          <button
                            className="share-button"
                            onClick={() => handleShare("facebook")}
                          >
                            <RiFacebookCircleFill />
                          </button>
                          <button
                            className="share-button"
                            onClick={() => handleShare("twitter")}
                          >
                            <RiTwitterXFill />
                          </button>
                          <button
                            className="share-button"
                            onClick={() => handleShare("linkedin")}
                          >
                            <RiLinkedinBoxFill />
                          </button>
                          <button
                            className="share-button"
                            onClick={() => handleShare("whatsapp")}
                          >
                            <RiWhatsappFill />
                          </button>
                          <button
                            className="share-button"
                            onClick={() => handleShare("email")}
                          >
                            <RiMailFill />
                          </button>
                        </div>
                        <div className="link-copy-container">
                          <input
                            type="text"
                            className="link-box"
                            value={window.location.href}
                            readOnly
                          />
                          <button
                            className="copy-button"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                window.location.href
                              );
                            }}
                          >
                            Copy Link
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="right-half-event-details">
                <div className="payment-info">
                  <div className="box larger-than-800">
                    <div>
                      <span className="original-price">$149</span>{" "}
                      <span className="discounted-price">
                        Limited Time Offer: $99.99
                      </span>
                    </div>
                    <a
                      className="button"
                      href="#popup1"
                      onClick={handleOpenPopup1}
                    >
                      <button>Register Now</button>
                    </a>
                  </div>
                </div>
              </div>

              {/* Second box for screen sizes less than 800px  */}

              <div className="box smaller-than-800">
                <span className="discounted-price">
                  Limited Time Offer @99.99 USD
                </span>
                <span className="discounted-price">
                  Bonus QnA session and Free Plagiarism Test.
                </span>
                <a className="button" href="#popup1" onClick={handleOpenPopup1}>
                  <button>Register Now</button>
                </a>
              </div>

              <div
                id="popup1"
                className={`overlay ${isPopup1Open ? "visible" : ""}`}
              >
                <div className="popup">
                  <div className="content">
                    {closingConfirm && (
                      <div className="closing-confirm">
                        <div className="closing-confirm-popup">
                          <span
                            className="close-of-closing"
                            onClick={handleConfirmClose}
                          >
                            ×
                          </span>
                          <h3>You're one step away from research success!</h3>
                          <p>
                            Are you sure you want to leave and miss this limited
                            time offer?
                          </p>
                          <button onClick={() => handleConfirmClose(false)}>
                            Continue
                          </button>
                          <a href="#a" onClick={() => handleConfirmClose(true)}>
                            Discard
                          </a>
                        </div>
                      </div>
                    )}
                    {!isFormSubmitted && (
                      <form
                        name="contact"
                        method="POST"
                        netlify
                        data-netlify="true"
                        onSubmit={(event) => handleSubmit(event)}
                      >
                        <h2>Register Now</h2>
                        <span className="close" onClick={handleClose}>
                          ×
                        </span>
                        <input type="hidden" name="form-name" value="contact" />
                        <input
                          type="hidden"
                          name="workshopId"
                          value="New Purposal"
                        />
                        <label htmlFor="name">Name:</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          onChange={(e) => setName(e.target.value)}
                          required
                        />

                        <label htmlFor="email">Email:</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        <label htmlFor="country">Country:</label>
                        <Select
                          options={countryList().getData()}
                          value={selectedCountry}
                          onChange={setSelectedCountry}
                          className="country-select"
                          required
                        />
                        <label>Pick Your Date:</label>
                        <div className="calendar">
                          {weekendDates.map((date) => {
                            const isSelected =
                              selectedDate instanceof Date &&
                              selectedDate.toDateString() ===
                                date.toDateString();

                            return (
                              <div
                                key={date.toISOString()}
                                className={`date-cell ${
                                  isSelected ? "selected" : ""
                                }`}
                                onClick={() => handleDateSelect(date)}
                              >
                                <strong>{date.toLocaleDateString()}</strong>
                                <br />
                                <span>
                                  {date.toLocaleDateString(undefined, {
                                    weekday: "long",
                                  })}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                        {selectedDate && seatsLeft && (
                          <p>
                            <strong>Only {seatsLeft} seats left!</strong>
                          </p>
                        )}
                        <button type="submit">Continue Checkout</button>
                      </form>
                    )}

                    {isPayPalVisible && (
                      <div
                        id="paypal-container-YF4R9EKFZRJ2L"
                        style={{ display: "block" }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="yellow-strip">
              <h6>
                Workshop Date: <span>Choose Your Date While Registering.</span>
              </h6>
              <RiShare2Fill
                color="#1E0A3C"
                className="share-icon"
                style={{
                  height: "2.2vmax",
                  width: "2.2vmax",
                  cursor: "pointer",
                }}
                onClick={togglePopup}
              />
              <a href="/new-proposal-quiz">Test Your Knowledge!</a>
            </div>

            <div className="proposal-display">
              <h2>Struggling with Your Research Proposal? You’re Not Alone!</h2>
              <ul>
                <li>🧐 Topic too broad?</li>
                <li>🔍 Unsure if your methodology fits?</li>
                <li>❌ Afraid of making mistakes you don’t even know exist?</li>
              </ul>
            </div>
            <div className="key-takeaways">
              <h2> About The Workshop</h2>
              <p className="about-info">
                This workshop is designed to help you overcome the common
                challenges and fears that can lead to proposal rejections.
                Whether you're struggling to choose the right topic, structure
                your sections, or meet academic standards, we’re here to guide
                you every step of the way.
              </p>
              <p className="about-info">
                With interactive learning and cutting-edge AI tools, you’ll gain
                the skills to craft a well-structured, impactful proposal. From
                selecting a compelling topic to writing each section with
                clarity and purpose, this workshop will equip you with the
                confidence and knowledge to succeed.
              </p>
            </div>

            <div className="key-takeaways">
              <h2>Comprehensive Curriculum:</h2>
              <ul>
                <li>
                  <strong>Session 1:</strong> Introduction to Research Proposal.
                </li>
                <li>
                  <strong>Session 2:</strong> Choosing a Research Title.
                </li>
                <li>
                  <strong>Session 3:</strong> Writing an Introduction.
                </li>
                <li>
                  <strong>Session 4:</strong> Writing the Literature Review.
                </li>
                <li>
                  <strong>Session 5:</strong> Choosing the Methodology.
                </li>
                <li>
                  <strong>Session 6:</strong> Formulating Expected Outcomes.
                </li>
              </ul>
              <button
                className="curriculum-download-btn"
                onClick={() => setShowForm(true)}
              >
                Download Detailed Curriculum
              </button>
            </div>

            {showForm && (
              <div className="modal">
                <form onSubmit={handleCurriculumSubmit}>
                  <RiCloseCircleFill
                    className="modal-close"
                    color="#000"
                    size={35}
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowForm(false)}
                  />
                  <h3>Enter Your Details</h3>
                  <p>
                    We will Send the Detailed Curriculum to the Email Address
                    you Provide.
                  </p>
                  <input
                    type="hidden"
                    id="CurriculumName"
                    name="CurriculumName"
                    value="New-Proposal-Curriculum"
                  />
                  <input
                    type="text"
                    name="curriculumUser"
                    id="curriculumUser"
                    placeholder="Your Name"
                    value={formData.curriculumUser}
                    onChange={handleInputChange}
                    required
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                  <Select
                    name="country"
                    options={countryList().getData()}
                    value={selectedCountry}
                    onChange={(option) => {
                      setSelectedCountry(option);
                      setFormData((prevData) => ({
                        ...prevData,
                        country: option.label,
                      }));
                    }}
                    className="country-select"
                    placeholder="Select Country"
                    required
                  />
                  {isSubmitting ? (
                    <div className="curriculum-loader"></div>
                  ) : (
                    <button type="submit" className="modal-submit">
                      Submit & Download
                    </button>
                  )}
                </form>
              </div>
            )}

            <div className="key-takeaways">
              <h2 className="bonus">
                Perks for Early Registrations (Enroll at an earliest to avail):
              </h2>
              <ul>
                <li>
                  <strong>BONUS Q&A Session to clear your doubts.</strong>
                </li>
                <li>
                  <strong>
                    Free Turnitin Check (Plagiarism + AI Detection)
                  </strong>
                </li>
              </ul>
            </div>

            <div className="key-takeaways">
              <h2>Key Features of the Workshop Include:</h2>
              <ul>
                <li>
                  <strong>Step-by-Step Curriculum:</strong> Covering everything
                  from choosing a research topic to planning your methodology
                  and outcomes.
                </li>
                <li>
                  <strong>Practical Tools:</strong> Learn to use AI-driven tools
                  to simplify complex tasks and enhance your proposal.
                </li>
                <li>
                  <strong>Beginner-Friendly Approach:</strong> Perfect for those
                  with little or no experience in proposal writing.
                </li>
                <li>
                  <strong>Flexible Access:</strong> Complete the workshop on
                  your schedule, with recordings available anytime.
                </li>
                <li>
                  <strong>Certification:</strong> Showcase your newly acquired
                  skills with a certificate of completion.
                </li>
              </ul>
            </div>

            <div className="key-takeaways">
              <h2>Workshop Details:</h2>
              <ul>
                <li>
                  <strong>Date:</strong>{" "}
                  <span>Choose Your Date While Registering.</span>
                </li>
                <li>
                  <strong>Type:</strong> Self Paced.
                </li>
              </ul>
            </div>

            <div className="key-takeaways">
              <h2>Fee Structure:</h2>
              <ul>
                <li>
                  <strong>Limited Time Offer: </strong> $99.99 <br />
                  Please note: Early Registrations will receive a bonus session
                  from our end.
                </li>
                <li>
                  <strong>Regular Price: </strong> $149
                </li>
              </ul>
            </div>

            {/* <h2 className="bottom-h2">Ready to Start Your Research Journey?</h2>
            <ul
              style={{ listStyle: "disc", marginLeft: "-1vw" }}
              className="ul-why-join"
            >
              <li>
                Register now and take the first step towards mastering your
                research proposal!
              </li>
            </ul> */}
            <NewProposalTestimonial />
            <div className="organizer-cont">
              <h3>Organised By-</h3>
              <div className="organizer-box">
                <div className="organizer-up">
                  <img src={Research_Pro_Logo} alt="ResearchPro Logo" />

                  <a href="/contactus">Contact Us</a>
                </div>
                <div className="organizer-down">
                  <h4>About Organisers</h4>
                  <p>
                    At ResearchPro, we help PhD and master’s students write
                    smarter, publish faster, and succeed in academia. Through
                    expert-led workshops on research proposals, thesis writing,
                    and data analysis, along with AI-driven tools to simplify
                    academic writing, we make the research journey easier.
                    Trusted by over 5,000 students worldwide, we’re here to
                    support your success!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Proposal;
