import React from "react";
import "../ProposalQuiz/ProposalQuestion.css";

const NewProposalQuestion = ({
  questionData,
  selectedOption,
  onSelectOption,
  onNext,
  disableNext,
}) => {
  const { question, options, styles, image } = questionData;

  return (
    <div className={`question-container ${styles?.question}`}>
      <img src={image.url} alt="Question" className="question-image" />
      <h2>{question}</h2>
      <div className={`options-container ${styles?.options}`}>
        {options.map((option, index) => (
          <div
            key={index}
            className={`option ${selectedOption === option ? "selected" : ""}`}
            onClick={() => onSelectOption(option)}
          >
            {option}
          </div>
        ))}
      </div>
      <button className="next-btn" onClick={onNext} disabled={disableNext}>
        Next
      </button>
    </div>
  );
};

export default NewProposalQuestion;
