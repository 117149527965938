import React from "react";
import "./ProposalTestimonial.css";
import User4 from "../assets/user4.jpg";
import User5 from "../assets/user5.jpg";
import User6 from "../assets/user6.jpg";

const ProposalTestimonial = () => {
  return (
    <>
      <div className="testimonial-cont">
        <h1 className="testimonials-header">Testimonials</h1>
        <div className="card-cont">
          <div className="cards">
            <p className="r-text">
              "This workshop was a game-changer! The AI tools helped so much!
              The tips on leveraging AI for academic writing were incredibly
              insightful. I feel much more confident tackling my dissertation
              now!"
            </p>
            <p className="r-name">
              <img src={User4} alt="User-dp" /> – Ethan, PhD Student, USA
            </p>
          </div>
          <div className="cards">
            <p className="r-text">
              "I finally understood how to structure my proposal step by step.
              The guidance was clear, practical, and easy to follow. I wish I
              had known these techniques earlier—it would have saved me so much
              time!"
            </p>
            <p className="r-name">
              <img src={User5} alt="User-dp" /> – Charlotte, Master's Student,
              UK
            </p>
          </div>
          <div className="cards">
            <p className="r-text">
              "The free Turnitin check was a great bonus. It gave me peace of
              mind knowing my work was original. The workshop is a must for
              anyone serious about their academic success!"
            </p>
            <p className="r-name">
              <img src={User6} alt="User-dp" /> – Carlos, Master's Student,
              Spain
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProposalTestimonial;
