import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import AnalysisQuizIntro from "./DataAnalysisQuiz/AnalysisQuizIntro";
import AnalysisQuestion from "./DataAnalysisQuiz/AnalysisQuestion";
import AnalysisFactScreen from "./DataAnalysisQuiz/AnalysisFactScreen";
import AnalysisQuizForm from "./DataAnalysisQuiz/AnalysisQuizForm";
import "./DataAnalysisQuiz.css";
import AQ1 from "../assets/AQ1.webp";
import AQ2 from "../assets/AQ2.webp";
import AQ3 from "../assets/AQ3.webp";
import AQ4 from "../assets/AQ4.webp";
import AQ5 from "../assets/AQ5.webp";
import AQ6 from "../assets/AQ6.webp";
import AQ7 from "../assets/AQ7.webp";
import AQ8 from "../assets/AQ8.webp";
import AQ9 from "../assets/AQ9.webp";
import AF1 from "../assets/AF1.webp";
import AF2 from "../assets/AF2.webp";
import AF3 from "../assets/AF3.webp";
import AF4 from "../assets/AF4.webp";
import AF5 from "../assets/AF5.webp";
import MessageImg from "../assets/Final-message.webp";

const DataAnalysisQuiz = () => {
  const navigate = useNavigate();
  const questions = [
    {
      question: "1. What’s your biggest challenge with data analysis? ",
      options: [
        "A) Choosing the right data collection methods. ",
        "B) Deciding which statistical tests to use.",
        "C) Interpreting qualitative data effectively.",
        "D) Presenting results in a clear and impactful way.",
      ],
      fact: "Over 70% of researchers struggle with at least one of these steps.",
      factImage: { url: AF1 },
      image: {
        url: AQ1,
      },
    },

    {
      question:
        "2. Have you ever felt stuck deciding between Quantitative and Qualitative analysis? ",
      options: [
        "A) Yes, I’m unsure when to use each method.",
        "B) No, but I don’t feel confident in applying them.",
        "C) Sometimes, and I wish I had clear guidelines.",
      ],
      fact: "Our workshop provides actionable insights to help you decide and apply the right methods.",
      factImage: { url: AF2 },
      image: {
        url: AQ2,
      },
    },
    {
      question:
        "3. Which of these data analysis tools do you feel confident using? ",
      options: [
        "A) Statistical methods like Regression, ANOVA, or Chi-Square.",
        "B) Qualitative techniques like Thematic or Content Analysis.",
        "C)  Both—but I want to deepen my understanding.",
        "D)  Neither—I need step-by-step guidance.",
      ],
      image: {
        url: AQ3,
      },
    },
    {
      question:
        "4. How often do you feel overwhelmed by statistical software or tools? ",
      options: [
        "A) Frequently—I don’t know where to start.",
        "B) Occasionally—I know the basics but struggle with advanced features.",
        "C) Rarely—but I’d like to master the tools completely",
      ],
      fact: "Our hands-on sessions simplify tools and teach practical applications.",
      factImage: { url: AF3 },
      image: {
        url: AQ4,
      },
    },
    {
      question: "5.What’s your biggest roadblock in presenting data results? ",
      options: [
        "A) Creating clear and compelling visuals.",
        "B) Interpreting results in a way that aligns with my objectives.",
        "C) Avoiding errors in my analysis.",
        "D) All of the above—I need guidance!",
      ],
      image: {
        url: AQ5,
      },
    },
    {
      question: "6. How often do you second-guess your statistical choices?",
      options: [
        "A) Always—I fear making the wrong decision.",
        "B) Sometimes—I wish I had expert advice.",
        "C) Rarely—but I’d like to be more confident.",
      ],
      fact: "Gain clarity and confidence with our expert-led guidance.",
      factImage: { url: AF4 },
      image: {
        url: AQ6,
      },
    },
    {
      question:
        "7. If you had access to expert training in data analysis, how likely are you to attend a workshop? ",
      options: [
        "A) Very likely—I want structured help to improve.",
        "B) Likely—I need better skills for my research.",
        "C) Unlikely—but I’d like to explore the workshop details.",
      ],
      image: {
        url: AQ7,
      },
    },
    {
      question: "8. What would make you feel more confident in data analysis?",
      options: [
        "A) Step-by-step guidance for statistical tests.",
        "B) Learning how to handle both qualitative and quantitative data.",
        "C) Hands-on practice with expert feedback.",
        "D) Tools for creating clear and impactful data visuals.",
        "E) Access to real-world examples and templates.",
      ],
      fact: "Our workshop covers all these areas, equipping you with everything you need.",
      factImage: { url: AF5 },
      image: {
        url: AQ8,
      },
    },
    {
      question:
        "9. Do you believe mastering data analysis is essential for impactful research?",
      options: [
        "A) Strongly agree—weak analysis can ruin great research.",
        "B) Agree—but I need guidance to get there.",
        "C) Disagree—I focus on other aspects of research.",
      ],
      image: {
        url: AQ9,
      },
    },
  ];

  const totalSteps = 1 + questions.length * 2 + 1;

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [state, setState] = useState({
    currentQuestion: 0,
    selectedOption: null,
    showIntro: true,
    showFact: false,
    showForm: false,
    showFinalMessage: false,
    userData: {
      name: "",
      email: "",
      country: selectedCountry?.label || "Not specified",
      QuizName: "Data-Analysis-Quiz",
    },
    imageLoaded: false,
  });

  const {
    currentQuestion,
    selectedOption,
    showIntro,
    showFact,
    showForm,
    showFinalMessage,
    userData,
    imageLoaded,
  } = state;

  // Determine the current step based on the state
  const currentStep = showIntro
    ? 1
    : showForm
    ? totalSteps
    : showFact
    ? currentQuestion * 2 + 2
    : currentQuestion * 2 + 1;

  const progressPercentage = (currentStep / totalSteps) * 100;

  useEffect(() => {
    const preloadImages = (imageUrls) => {
      imageUrls.forEach((url) => {
        const img = new Image();
        img.src = url;
      });
    };
    const allImages = [
      AQ1,
      AQ2,
      AQ3,
      AQ4,
      AQ5,
      AQ6,
      AQ7,
      AQ8,
      AQ9,
      AF1,
      AF2,
      AF3,
      AF4,
      AF5,
      MessageImg,
    ];
    preloadImages(allImages);
  }, []);

  const handleOptionSelect = (option) => {
    setState((prevState) => ({ ...prevState, selectedOption: option }));
  };

  const handleNext = () => {
    if (questions[currentQuestion].fact && !showFact) {
      setState((prevState) => ({
        ...prevState,
        showFact: true,
        selectedOption: null,
        imageLoaded: false,
      }));
    } else {
      moveToNextQuestion();
    }
  };

  const moveToNextQuestion = () => {
    const isLastQuestion = currentQuestion + 1 === questions.length;

    if (isLastQuestion && !showFinalMessage) {
      setState((prevState) => ({
        ...prevState,
        showFinalMessage: true,
        selectedOption: null,
        showFact: false,
      }));
    } else if (isLastQuestion && showFinalMessage) {
      setState((prevState) => ({
        ...prevState,
        showForm: true,
        showFinalMessage: false,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: prevState.currentQuestion + 1,
        showFact: false,
        selectedOption: null,
        imageLoaded: false,
      }));
    }
  };

  const handleImageLoad = () => {
    setState((prevState) => ({ ...prevState, imageLoaded: true }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      userData: { ...prevState.userData, [name]: value },
      country: selectedCountry?.label || "Not specified",
    }));
  };

  const quizFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://researchpro.online/data-analysis-quiz/quizFormData",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(userData),
        }
      );

      if (!response.ok) throw new Error("Failed to save form data.");

      navigate("/analysis");
    } catch (error) {
      alert("There was an error submitting the form. Please try again later.");
    }
  };

  const animationVariants = {
    enter: { x: "100%", opacity: 0, scale: 1 },
    center: { x: 0, opacity: 1, scale: 1, transition: { duration: 0.5 } },
    exit: { x: "-100%", opacity: 0, scale: 1, transition: { duration: 0.5 } },
  };

  const FinalMessage = ({ onContinue }) => (
    <div className="final-message-cont">
      <img className="final-message-img" src={MessageImg} alt="Message-img" />
      <p className="final-message-p">
        <span className="final-message-span">🌟</span> If you found yourself
        agreeing with many of the challenges above, our workshop is designed for
        YOU!
      </p>
      <p className="final-message-p">
        <span className="final-message-span">💡</span> Gain clarity, confidence,
        and practical skills to simplify your research and produce impactful
        results.
      </p>
      <h4 className="final-message-h4">
        🚀 Don’t wait—register today and transform your data analysis skills!
      </h4>
      <button onClick={onContinue} className="final-message-btn">
        Next
      </button>
    </div>
  );

  return (
    <div className="analysis-quiz-cont">
      {/* Progress Bar (new addition) */}
      <div className="progress-bar-container">
        <div
          className="progress-bar"
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
      <AnimatePresence mode="wait">
        {showIntro && (
          <motion.div
            key="intro"
            initial="enter"
            animate="center"
            exit="exit"
            variants={animationVariants}
          >
            <AnalysisQuizIntro
              onNext={() =>
                setState((prevState) => ({ ...prevState, showIntro: false }))
              }
            />
          </motion.div>
        )}
        {!showIntro && !showForm && !showFact && !showFinalMessage && (
          <motion.div
            key={`question-${currentQuestion}`}
            initial="enter"
            animate="center"
            exit="exit"
            variants={animationVariants}
          >
            <AnalysisQuestion
              questionData={questions[currentQuestion]}
              selectedOption={selectedOption}
              onSelectOption={handleOptionSelect}
              onNext={handleNext}
              disableNext={!selectedOption}
              onImageLoad={() =>
                setState((prevState) => ({ ...prevState, imageLoaded: true }))
              }
              imageLoaded={imageLoaded}
            />
          </motion.div>
        )}
        {showFact && (
          <AnalysisFactScreen
            fact={questions[currentQuestion].fact}
            onContinue={moveToNextQuestion}
            factImage={questions[currentQuestion].factImage}
          />
        )}
        {showFinalMessage && (
          <motion.div
            key="final-message"
            initial="enter"
            animate="center"
            exit="exit"
            variants={animationVariants}
          >
            <FinalMessage onContinue={moveToNextQuestion} />
          </motion.div>
        )}
        {showForm && (
          <motion.div
            key="form"
            initial="enter"
            animate="center"
            exit="exit"
            variants={animationVariants}
          >
            <AnalysisQuizForm
              userData={userData}
              onChange={handleInputChange}
              onSubmit={quizFormSubmit}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default DataAnalysisQuiz;
